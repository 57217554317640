import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { BLOCKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import StyledBackgroundHero from './background-header'
import Layout from './layout'
import SEO from './seo'

const BlogSection = styled.section`
  @media (min-width: 720px) {
    margin: 0 3rem;
  }
`
const BlogH1 = styled.h1`
  text-align: center;
  margin-bottom: 5rem;
`
const BlogH3 = styled.h3`
  font-weight: 700;
  font-size: 1.7rem;
  margin: 3rem 0;
`
const BlogIMG = styled.img`
  margin: 3rem 0;
`
const BlogLI = styled.li`
  list-style-type: disc;
  font-size: 1.2rem;
  margin: 1.2rem 0;
  line-height: 2.3rem;

  @media (max-width: 400px) {
    font-size: 1rem;
    line-height: 1.5;
  }
`

const documentToReactComponentsOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { src } = node.data.target.fixed
      return <BlogIMG width="100%" src={src} />
    },
    [BLOCKS.HEADING_3]: (node) => {
      return <BlogH3>{node.content[0].value}</BlogH3>
    },
    [BLOCKS.LIST_ITEM]: (node) => (
      <BlogLI>{node.content[0].content[0].value}</BlogLI>
    ),
  },
}

const BlogArticleTemplate = ({ data }) => {
  const heroImage = data.contentfulBlogArtikel.featured_image.fluid
  const ogImage = data.contentfulBlogArtikel.featured_image.file.url
  const description = data.contentfulBlogArtikel.content_preview.content_preview

  return (
    <Layout hero={<StyledBackgroundHero imageData={heroImage} />}>
      <SEO
        title={data.contentfulBlogArtikel.title}
        image={ogImage}
        description={description}
      />
      <BlogH1>{data.contentfulBlogArtikel.title}</BlogH1>
      <BlogSection>
        {renderRichText(
          data.contentfulBlogArtikel.article_content,
          documentToReactComponentsOptions
        )}
      </BlogSection>
    </Layout>
  )
}

export default BlogArticleTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogArtikel(slug: { eq: $slug }) {
      title
      published_at(formatString: "MMMM Do, YYYY")
      author {
        name
      }

      content_preview {
        content_preview
      }

      article_content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            fixed(width: 1600) {
              width
              height
              src
              srcSet
            }
          }
        }
      }

      title
      slug
      featured_image {
        file {
          url
        }

        fluid(quality: 80, maxWidth: 1440) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
