/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { slide as BurgerMenu } from 'react-burger-menu'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import NavigationItems, { ExtraNavigationItems } from './navigation'
import Header from './header'
import './layout.css'
import logo from '../images/logo.svg'
import StyledBackgroundHero from './background-header'

const Footer = styled.footer`
  background-color: #00382f;
  margin-top: 1rem;
  color: white;
  padding: 1.5rem 0;
  text-align: center;

  & a {
    color: white;
  }
`

const CenteredDiv = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 1.45rem;
`

const Main = styled.main`
  margin: 3rem 0;
  min-height: 50vh;

  @media (min-width: 720px) {
    margin: 5rem 0;
  }
`

const Trennlinie = styled.div`
  height: 1px;
  background: #eee;
  margin: 1.5rem 0;
`

const Layout = ({ children, hero, isStartpage }) => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "01_LP_header.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div
        css={css`
          @media (min-width: 721px) {
            display: none;
          }
        `}
      >
        <BurgerMenu disableAutoFocus right>
          <NavigationItems />
          <Trennlinie />
          <ExtraNavigationItems />
        </BurgerMenu>
      </div>

      <Header siteTitle={data.site.siteMetadata.title} />
      {hero ? (
        hero
      ) : (
        <StyledBackgroundHero
          mobileHeight="130px"
          desktopHeight="130px"
          imageData={data.desktop.childImageSharp.fluid}
        />
      )}
      <CenteredDiv>
        <Main>{children}</Main>
      </CenteredDiv>
      <Footer>
        <img width="200" src={logo} alt="Logo Yogastudio Olga Hilz" />
        <br />
        <Link to="/impressum">Impressum</Link>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isStartpage: PropTypes.bool,
}

export default Layout
